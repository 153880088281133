import { ChangeEvent, FC } from "react";
import classes from "./ListConfiguration.module.scss";
import { sortOptions } from "../../../../../../pages/auth/services/services-list/constants";
import CustomSearch from "../../../../../ui/search";
import CustomSelect from "../../../../../ui/select";

interface IListConfiguration {
  onSortChange: (name: string) => void;
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  query: string;
  value: string;
}

const ListConfiguration: FC<IListConfiguration> = ({
  onSortChange,
  onSearch,
  query,
  value,
}) => {
  return (
    <div className={classes.config}>
      <div className={classes.content}>
        <p className={classes["sort--paragraph"]}>Sort By:</p>
        <CustomSelect
          options={sortOptions}
          onChangeCB={onSortChange}
          value={value}
        />
        <CustomSearch
          width="384px"
          height="30px"
          onSearch={onSearch}
          query={query}
        />
      </div>
    </div>
  );
};

export default ListConfiguration;
