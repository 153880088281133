import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import {
  passwordDefaultValues,
  passwordFields,
  passwordSchema,
} from "./constants";
import classes from "./Password.module.scss";
import { useChangePassword } from "../../../../services/utils/hooks/routes/useUser";
import { passwordType } from "../../../../services/utils/ts/types";
import CustomButton from "../../../ui/button";
import CustomInput from "../../../ui/input/input";

const Password = () => {
  const onSuccess = () => {
    reset({ oldPassword: "", password: "", confirmPassword: "" });
    clearErrors(["oldPassword", "password", "confirmPassword"]);
  };

  const { mutate, isLoading } = useChangePassword(onSuccess);

  const state = useForm({
    mode: "onSubmit",
    defaultValues: passwordDefaultValues,
    resolver: yupResolver(passwordSchema),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    clearErrors,
  } = state;

  const onSubmit = (passwordData: passwordType) => {
    mutate(passwordData);
  };

  return (
    <div className={classes["password--page"]}>
      <p className="heading--section">Change Password</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes["profile--content"]}>
          {passwordFields.map((field, index) => {
            return (
              <Controller
                key={index}
                control={control}
                name={field.name}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    name={field.name}
                    onChange={onChange}
                    selected={value}
                    label={field.label}
                    type={field.type}
                    errorMessage={errors[field.name]}
                  />
                )}
              />
            );
          })}
        </div>

        <CustomButton
          type="submit"
          variant="contained"
          disabled={isLoading}
          className={classes["save-changes-btn"]}
        >
          Save Changes
        </CustomButton>
      </form>
    </div>
  );
};

export default Password;
