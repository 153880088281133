import * as yup from "yup";

export const profileNameFields = [
  {
    name: "firstName",
    label: "First Name",
    type: "text",
  },
  {
    name: "lastName",
    label: "Last Name",
    type: "text",
  },
] as const;

export const profileInfoFields = [
  {
    name: "phone",
    label: "Phone",
    type: "text",
  },
  {
    name: "dob",
    label: "Date of Birth",
    type: "date",
  },
] as const;

export const profileSchema = yup.object({
  firstName: yup
    .string()
    .min(2, "First Name should be min 2 characters")
    .required("First Name is required"),
  lastName: yup
    .string()
    .min(2, "Last Name should be min 2 characters")
    .required("Last Name is required"),
});
