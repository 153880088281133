import { yupResolver } from "@hookform/resolvers/yup";
import { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import classes from "./EditService.module.scss";
import {
  useDeleteService,
  useEditService,
} from "../../../../services/utils/hooks/routes/useService";
import {
  createServiceType,
  editServiceType,
} from "../../../../services/utils/ts/types";
import CustomButton from "../../../ui/button";
import CustomDialog from "../../../ui/dialog";
import CustomInput from "../../../ui/input/input";
import {
  createServiceSchema,
  serviceInputFields,
} from "../add-service/constants";

interface IEditService {
  service: editServiceType;
}

const EditService: FC<IEditService> = ({ service }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const navigate = useNavigate();

  const onSuccess = () => {
    navigate("/services-list");
  };

  const editServiceDefaultValues = {
    // name: service.name,
    userName: service.userName || "",
    websiteLink: service.websiteLink || "",
    passwordResetLink: service.passwordResetLink || "",
  };

  const state = useForm({
    mode: "onSubmit",
    defaultValues: editServiceDefaultValues,
    resolver: yupResolver(createServiceSchema),
  });

  const { mutate, isLoading: saveLoading } = useEditService(onSuccess);
  const { mutate: deleteMutate, isLoading: deleteLoading } =
    useDeleteService(onSuccess);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = state;

  const onSubmit = (serviceData: createServiceType) => {
    mutate({
      ...serviceData,
      id: service.id,
      websiteLink: serviceData.websiteLink || null,
      passwordResetLink: serviceData.passwordResetLink || null,
    });
  };

  const handleDeleteClick = () => {
    setIsDialogOpen(true);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.editForm}>
        {Object.values(serviceInputFields).map((field, index) => {
          return (
            <Controller
              key={index}
              control={control}
              name={field.name}
              render={({ field: { onChange, value } }) => (
                <CustomInput
                  name={field.name}
                  onChange={onChange}
                  selected={value}
                  label={field.label}
                  type={field.type}
                  errorMessage={errors[field.name]}
                />
              )}
            />
          );
        })}
        <div className={classes.buttons}>
          <CustomButton
            type="submit"
            variant="contained"
            disabled={saveLoading || deleteLoading}
          >
            Save
          </CustomButton>
          <CustomButton
            variant="outlined"
            disabled={saveLoading || deleteLoading}
            onClick={handleDeleteClick}
          >
            Delete
          </CustomButton>
        </div>
      </form>
      {isDialogOpen && (
        <CustomDialog
          title="Are you sure you want to delete service?"
          onAction={() => {
            deleteMutate({ id: service.id });
          }}
          onClose={handleClose}
          isDialogOpen={isDialogOpen}
        ></CustomDialog>
      )}
    </>
  );
};

export default EditService;
