import { useMutation, useQuery } from "react-query";
import {
  createServiceRequest,
  deleteServiceRequest,
  editServiceRequest,
  getServiceByID,
  getServiceNameRequest,
  getSevicesRequest,
} from "../../functions/routes/service";
import { ISuccessData } from "../../ts/interface";

export const useGetServicesList = (data: Record<string, any>) => {
  const { id, offset, size, sort, query, userId } = data;
  return useQuery(
    [id, offset, size, sort, query],
    () => getSevicesRequest({ offset, size, sort, query }),
    {
      refetchOnWindowFocus: false,
      enabled: !userId,
    }
  );
};

export const useGetServicesNameList = (
  query: string,
  onSuccess: (data: any) => void
) => {
  return useQuery(
    ["get-service-name", query],
    () => getServiceNameRequest({ query }),
    { onSuccess }
  );
};

export const useCreateService = (onSuccess: (data: ISuccessData) => void) => {
  return useMutation(createServiceRequest, {
    onSuccess,
  });
};

export const useGetServiceById = (serviceId: string) => {
  return useQuery(["get-service-by-ID", serviceId], getServiceByID, {
    enabled: !!serviceId,
    refetchOnWindowFocus: false,
  });
};

export const useEditService = (onSuccess: (data: ISuccessData) => void) => {
  return useMutation(editServiceRequest, {
    onSuccess,
  });
};

export const useDeleteService = (onSuccess: (data: ISuccessData) => void) => {
  return useMutation(deleteServiceRequest, {
    onSuccess,
  });
};
