import { makeStyles } from "@material-ui/core/styles";
import { Add } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FC, ReactNode } from "react";
import classes from "./Modal.module.scss";
import CustomButton from "../button";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  dialogActions: {
    padding: theme.spacing(2),
  },
}));

interface Props {
  title: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  handleOk?: (e: any) => void;
  okText?: string;
  cancelText?: string;
  formId?: string;
  children: ReactNode;
  hasIcon?: boolean;
  buttonDisabled?: boolean;
}

const Modal: FC<Props> = ({
  title,
  open,
  setOpen,
  okText = "Save",
  cancelText = "",
  children,
  handleOk,
  formId,
  hasIcon = true,
  buttonDisabled = false,
}) => {
  const muiClasses = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ m: 0, p: 2 }}
        className={classes.modal}
      >
        <DialogTitle className={classes.title}>{title}</DialogTitle>
        <DialogContent className={muiClasses.dialogContent}>
          {children}
        </DialogContent>
        <DialogActions
          className={`${muiClasses.dialogActions} ${classes.buttons}`}
        >
          <CustomButton
            className={classes.button}
            onClick={handleOk}
            startIcon={hasIcon && <Add />}
            type={"submit"}
            form={formId}
            disabled={buttonDisabled}
          >
            {okText}
          </CustomButton>
          {cancelText && (
            <CustomButton
              className={classes.button}
              onClick={handleClose}
              variant={"outlined"}
            >
              {cancelText}
            </CustomButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Modal;
