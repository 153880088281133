import { atom } from "recoil";
import { SnackbarMessageType } from "../../utils/ts/types";

interface ISnackbar {
  text: string;
  variant: SnackbarMessageType;
}

export const snackbarState = atom<ISnackbar | null>({
  key: "snackbar",
  default: null,
});
