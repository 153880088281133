import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowDropDown } from "@mui/icons-material";
import { debounce } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  createServiceDefaultValues,
  createServiceSchema,
  serviceInputFields,
} from "./constants";
import classes from "./CreateService.module.scss";
import {
  useCreateService,
  useGetServicesNameList,
} from "../../../../services/utils/hooks/routes/useService";
import { createServiceType } from "../../../../services/utils/ts/types";
import BaseAutocomplete from "../../../ui/autocomplete/autocomplete";
import CustomButton from "../../../ui/button";
import CustomInput from "../../../ui/input/input";

interface IServiceName {
  inputValue?: string;
  name: string;
  passwordResetLink?: string;
  websiteLink?: string;
}

const { userName, websiteLink, passwordResetLink } = serviceInputFields;

const CreateService = () => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [services, setServices] = useState([]);
  const [service, setService] = useState<IServiceName | null>(null);

  const navigate = useNavigate();

  const onSuccess = () => {
    navigate("/services-list");
  };

  const { mutate, isLoading } = useCreateService(onSuccess);

  const state = useForm({
    mode: "onSubmit",
    defaultValues: createServiceDefaultValues,
    resolver: yupResolver(createServiceSchema),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = state;

  const onSubmit = (serviceData: createServiceType) => {
    mutate({
      ...serviceData,
      websiteLink: serviceData.websiteLink || null,
      passwordResetLink: serviceData.passwordResetLink || null,
      name: service?.name || "",
    });
  };

  useGetServicesNameList(service?.name || "", (res) => {
    setServices(res.services);
  });

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const existedService =
      services.find((item: IServiceName) => item.name === event.target.value) ||
      {};
    setService({
      ...existedService,
      name: event.target.value,
    });
  };
  const debouncedHandleSearch = debounce(handleSearch, 1000);

  useEffect(() => {
    state.setValue("websiteLink", service?.websiteLink || "");
    state.setValue("passwordResetLink", service?.passwordResetLink || "");
  }, [service?.websiteLink, service?.passwordResetLink, state]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.createForm}>
      <BaseAutocomplete
        debouncedHandleSearch={debouncedHandleSearch}
        selected={service}
        setSelected={setService}
        optionData={services}
        label={"Service Name"}
      />
      <Controller
        control={control}
        name={userName.name}
        render={({ field: { onChange, value } }) => (
          <CustomInput
            name={userName.name}
            onChange={onChange}
            selected={value}
            label={userName.label}
            type={userName.type}
            errorMessage={errors[userName.name]}
          />
        )}
      />
      {isExpanded && (
        <>
          <Controller
            control={control}
            name={websiteLink.name}
            render={({ field: { onChange, value } }) => (
              <CustomInput
                name={websiteLink.name}
                onChange={onChange}
                selected={value}
                label={websiteLink.label}
                type={websiteLink.type}
                errorMessage={errors[websiteLink.name]}
              />
            )}
          />
          <Controller
            control={control}
            name={passwordResetLink.name}
            render={({ field: { onChange, value } }) => (
              <CustomInput
                name={passwordResetLink.name}
                onChange={onChange}
                selected={value}
                label={passwordResetLink.label}
                type={passwordResetLink.type}
                errorMessage={errors[passwordResetLink.name]}
              />
            )}
          />
        </>
      )}
      {!isExpanded && (
        <CustomButton
          variant="text"
          sxStyles={{
            border: "none",
            padding: "0",
            width: "fit-content",
            margin: "0",
          }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          More options <ArrowDropDown />
        </CustomButton>
      )}

      <CustomButton type="submit" variant="contained" disabled={isLoading}>
        Save
      </CustomButton>
    </form>
  );
};

export default CreateService;
