import { ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import "./assets/styles/style.scss";
import { theme } from "./assets/styles/mui/theme";
import { AppContextProvider } from "./context/AppContext";
import AppRoutes from "./routes";

const App = () => {
  const queryClient = new QueryClient();

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider maxSnack={3}>
          <BrowserRouter>
            <AppContextProvider>
              <AppRoutes />
            </AppContextProvider>
          </BrowserRouter>
        </SnackbarProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
