import { FormHelperText, TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import classes from "../input/CustomInput.module.scss";

interface ISelectedOption {
  inputValue?: string;
  name: string;
}

interface IBaseAutocomplete {
  debouncedHandleSearch: (value: React.ChangeEvent<HTMLInputElement>) => void;
  selected: ISelectedOption | null;
  setSelected: (selected: ISelectedOption | null) => void;
  optionData: ISelectedOption[];
  label: string;
}

const filter = createFilterOptions<ISelectedOption>();

const BaseAutocomplete = ({
  debouncedHandleSearch,
  selected,
  setSelected,
  optionData,
  label,
}: IBaseAutocomplete) => {
  return (
    <div className={classes["custom--input"]}>
      <div className={classes["helper--text"]}>
        <FormHelperText sx={{ color: "#000000", fontWeight: 600 }}>
          {label}
        </FormHelperText>
      </div>
      <Autocomplete
        value={selected}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setSelected({
              name: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            setSelected({
              name: newValue.inputValue,
            });
          } else {
            setSelected(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;

          const isExisting = options.some(
            (option) => inputValue === option.name
          );
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              name: `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="service-name"
        options={optionData}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={debouncedHandleSearch}
            sx={{
              textTransform: "uppercase",

              "& input::placeholder": {
                textTransform: "none",
              },
            }}
            name="name"
            placeholder="Enter service name"
          />
        )}
      />
    </div>
  );
};

export default BaseAutocomplete;
