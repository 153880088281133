import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";
import {
  confirmInviteRequest,
  deleteFamilyRequest,
  getFamiliesRequest,
  getFamilyParentsRequest,
  getParentsServices,
  inviteFamilyRequest,
} from "../../functions/routes/family";
import { IErrorResponse, ISuccessData } from "../../ts/interface";

export const useGetParentsServicesList = (data: Record<string, any>) => {
  const { id, offset, size, sort, query, userId } = data;
  return useQuery(
    [id, offset, size, sort, query, userId],
    () => getParentsServices({ offset, size, sort, query, userId }),
    {
      refetchOnWindowFocus: false,
      enabled: !!userId,
    }
  );
};

export const useGetFamilies = (
  data: Record<string, any>,
  onSuccess: (data: any) => void
) => {
  const { id, offsetF, limitF, queryF } = data;

  return useQuery(
    [id, offsetF, limitF, queryF],
    () =>
      getFamiliesRequest({
        limit: limitF,
        offset: offsetF,
        query: queryF,
      }),
    {
      onSuccess,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetFamilyParents = (
  data: Record<string, any>,
  onSuccess: (data: any) => void
) => {
  const { id, offsetP, limitP, queryP } = data;

  return useQuery(
    [id, offsetP, limitP, queryP],
    () =>
      getFamilyParentsRequest({
        limit: limitP,
        offset: offsetP,
        query: queryP,
      }),
    {
      onSuccess,
      refetchOnWindowFocus: false,
    }
  );
};

export const useInviteFamily = (onSuccess: (data: ISuccessData) => void) => {
  return useMutation(inviteFamilyRequest, {
    onSuccess,
  });
};

export const useDeleteFamily = (onSuccess: (data: ISuccessData) => void) => {
  return useMutation(deleteFamilyRequest, {
    onSuccess,
  });
};

export const useConfirmInvite = (
  onSuccess: (data: { userExist?: boolean; success?: boolean }) => void,
  onError: (data: AxiosError<IErrorResponse>) => void
) => {
  return useMutation(confirmInviteRequest, {
    onSuccess,
    onError,
  });
};
