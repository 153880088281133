import { helpType, mailType, passwordType, profileType } from "../../ts/types";
import { request } from "../axios-rq";

export const getMeRequest = () => {
  return request({ url: "users/me", method: "get" });
};

export const saveProfileRequest = (data: profileType) => {
  return request({ url: "users/me/", method: "put", data });
};

export const changePasswordRequest = (data: passwordType) => {
  return request({ url: "users/me/change-password", method: "patch", data });
};

export const changeEmailRequest = (data: mailType) => {
  return request({ url: "users/me/change-email", method: "patch", data });
};
export const helpRequest = (data: helpType) => {
  return request({ url: "users/me/help", method: "post", data });
};
