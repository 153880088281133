import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";
import { getCookies } from "../../functions/cookies";
import {
  changeEmailRequest,
  changePasswordRequest,
  getMeRequest,
  helpRequest,
  saveProfileRequest,
} from "../../functions/routes/user";
import { IErrorResponse, ISuccessData } from "../../ts/interface";
import { userType } from "../../ts/types";

export const useGetMe = (
  onSuccess: (data: userType) => void,
  onError: (error: AxiosError<IErrorResponse>) => void
) => {
  return useQuery("getMe", getMeRequest, {
    onSuccess,
    onError,
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!getCookies("techwell-token"),
  });
};

export const useSaveProfile = (onSuccess: (data: ISuccessData) => void) => {
  return useMutation(saveProfileRequest, {
    onSuccess,
  });
};

export const useChangePassword = (onSuccess: (data: ISuccessData) => void) => {
  return useMutation(changePasswordRequest, {
    onSuccess,
  });
};

export const useChangeEmail = (onSuccess: (data: ISuccessData) => void) => {
  return useMutation(changeEmailRequest, {
    onSuccess,
  });
};

export const useHelp = (onSuccess: (data: ISuccessData) => void) => {
  return useMutation(helpRequest, {
    onSuccess,
  });
};
