import { useSearchParams } from "react-router-dom";
import classes from "./AddService.module.scss";
import CreateService from "../../../../components/auth/services/add-service";
import EditService from "../../../../components/auth/services/edit-service";
import HeadingPage from "../../../../components/ui/heading-page";
import Loader from "../../../../components/ui/loader";
import { useGetServiceById } from "../../../../services/utils/hooks/routes/useService";

const AddService = () => {
  const [searchParams] = useSearchParams();

  const serviceId = searchParams.get("id");
  const { data, isFetching } = useGetServiceById(serviceId!);

  if (isFetching) {
    return <Loader />;
  }

  return (
    <div className={classes.servicePage}>
      <div className={classes.service}>
        <HeadingPage headingName={`${serviceId ? "Edit" : "New"} Service`} />
        {serviceId ? <EditService service={data} /> : <CreateService />}
      </div>
    </div>
  );
};

export default AddService;
