export interface IDrawerPathMap {
  Benefits: string;
  "My Apps & Services": string;
  "My Family": string;
  Settings: string;
}

export const pages = [
  // "Savings",
  "Usernames",
];
export const settings = [
  "Settings",
  "CoPilot: Chrome",
  "CoPilot: Safari",
  "Onboarding",
  "My Family",
  "Log Out",
];

export const menuOptions = [
  { name: "Savings", data: ["Benefits"] },
  { name: "Services", data: ["My Apps & Services", "My Family"] },
  {
    name: "Info",
    data: ["Settings", "Onboarding", "CoPilot: Chrome", "CoPilot: Safari"],
  },
  { name: "Account", data: ["AccountName", "Log Out"] },
];

export const pagePathMap: Record<string, string> = {
  // Savings: "/benefits",
  Usernames: "/services-list",
};

export const settingPathMap: Record<string, string> = {
  Settings: "/settings",
  Companion: "/companion",
  Onboarding: "/onboarding",
  "My Family": "/family",
};

export const drawerPathMap: IDrawerPathMap = {
  Benefits: "/benefits",
  "My Apps & Services": "/services-list",
  "My Family": "/family",
  Settings: "/settings",
};

export const coPilotChromeURL =
  "https://chrome.google.com/webstore/detail/go-go-quincy/jkjigoaapnlhfjfenbnbnljbnlkjmgfk";

export const coPilotSafariURL =
  "https://apps.apple.com/am/app/go-go-quincy/id6466471201?mt=12";

export const onboardingURL = "https://www.gogoquincy.com/onboarding";
