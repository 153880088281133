import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FC, memo, ReactNode } from "react";
import CustomButton from "../button";

interface ICustomDialog {
  title: string;
  onAction: () => void;
  onClose: () => void;
  isDialogOpen: boolean;
  content?: string;
  children?: ReactNode;
}

const CustomDialog: FC<ICustomDialog> = memo(
  ({ onClose, title, content, onAction, children }) => {
    return (
      <Dialog open onClose={onClose} sx={{ padding: "30px" }}>
        <DialogTitle>{title}</DialogTitle>
        {content && <DialogContent>{children}</DialogContent>}
        <DialogActions>
          <CustomButton variant="outlined" onClick={onClose}>
            Cancel
          </CustomButton>
          <CustomButton onClick={onAction} autoFocus>
            Submit
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
  }
);

export default CustomDialog;
