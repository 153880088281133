import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { phoneDefaultValues, phoneFields, phoneSchema } from "./constants";
import classes from "./HelpButton.module.scss";
import { ReactComponent as Help } from "../../../../../../assets/icons/help.svg";
import CustomInput from "../../../../../../components/ui/input/input";
import Modal from "../../../../../../components/ui/modal";
import { useAddAnalytics } from "../../../../../../services/utils/hooks/routes/useAnalytics";
import { useHelp } from "../../../../../../services/utils/hooks/routes/useUser";
import { helpType } from "../../../../../../services/utils/ts/types";

const HelpButton = () => {
  const { mutate: analyticsMutate } = useAddAnalytics();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onSuccess = () => {
    setIsModalOpen(false);
  };

  const { isLoading, mutate } = useHelp(onSuccess);

  const state = useForm({
    mode: "onSubmit",
    resolver: yupResolver(phoneSchema),
    defaultValues: phoneDefaultValues,
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = state;

  const handleAddAnalytics = () => {
    analyticsMutate({
      event: "Help",
    });
  };

  const handleButtonClick = () => {
    setIsModalOpen(true);
    handleAddAnalytics();
  };

  const onSubmit = (finalData: helpType) => {
    mutate(finalData);
  };

  return (
    <>
      <button
        className={classes["help--button"]}
        type="button"
        onClick={handleButtonClick}
        disabled={isLoading}
      >
        <Help />
        <p className={classes.help}>TechWell help</p>
      </button>
      <Modal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        handleOk={handleSubmit}
        okText={"Submit"}
        formId={"help"}
        title={"Help"}
      >
        <form
          id={"help"}
          onSubmit={handleSubmit((finalData) => onSubmit(finalData))}
        >
          {phoneFields.map((field, id) => (
            <Controller
              key={id}
              control={control}
              name={field.name}
              render={({ field: { onChange, value } }) => (
                <CustomInput
                  name={field.name}
                  onChange={onChange}
                  selected={value}
                  label={field.label}
                  type={field.type}
                  errorMessage={errors[field.name]}
                />
              )}
            />
          ))}
        </form>
      </Modal>
    </>
  );
};

export default HelpButton;
