import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import classes from "./GuestLayout.module.scss";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import Snackbar from "../../components/ui/snackbar";
import { useAppContext } from "../../context/AppContext";

const GuestLayout = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated } = useAppContext();

  return isAuthenticated ? (
    <Navigate to="/" replace={true} />
  ) : (
    <div className={classes["auth-page"]}>
      <div className={classes.auth}>
        <div className={classes.logo}>
          <Logo />
        </div>
        <div className={classes.content}>
          <Snackbar>{children}</Snackbar>
        </div>
      </div>
    </div>
  );
};

export default GuestLayout;
