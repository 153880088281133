import { yupResolver } from "@hookform/resolvers/yup";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { mailDefaultValues, mailFields, mailSchema } from "./constants";
import { getMailURL } from "../../../../../services/utils/functions";
import { useChangeEmail } from "../../../../../services/utils/hooks/routes/useUser";
import { mailType } from "../../../../../services/utils/ts/types";
import CustomButton from "../../../../ui/button";
import CustomInput from "../../../../ui/input/input";

interface IChangeMailDialog {
  open: boolean;
  onClose: () => void;
  currentMail: string;
}

const ChangeMailDialog: FC<IChangeMailDialog> = ({
  open,
  onClose,
  currentMail,
}) => {
  const [sentMail, setSentMail] = useState("");

  const onSuccess = async () => {
    const email = watch().email;
    setSentMail(email);
    reset({ email: "" });
    const timeOut = setTimeout(() => {
      window.location.href = getMailURL(email);
    }, 2000);
    return () => {
      clearTimeout(timeOut);
    };
  };
  const { mutate, isLoading } = useChangeEmail(onSuccess);
  const state = useForm({
    mode: "onSubmit",
    defaultValues: mailDefaultValues,
    resolver: yupResolver(mailSchema),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    clearErrors,
    watch,
  } = state;

  const handleClose = () => {
    onClose();
    reset({ email: "", confirmEmail: "", password: "" });
    clearErrors(["email", "confirmEmail", "password"]);
  };

  const onSubmit = (mailData: mailType) => {
    mutate(mailData);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Change Your Email Address"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Your current email address is {currentMail}. Please enter your new
          email address and your password.
        </DialogContentText>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            {mailFields.map((field, index) => {
              return (
                <Controller
                  key={index}
                  control={control}
                  name={field.name}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <CustomInput
                        name={field.name}
                        onChange={onChange}
                        selected={value}
                        label={field.label}
                        type={field.type}
                        errorMessage={errors[field.name]}
                      />
                    </>
                  )}
                />
              );
            })}
          </div>
          {sentMail && (
            <p className="form-warning">
              Check your email address in order to verify
            </p>
          )}

          <CustomButton type="submit" variant="contained" disabled={isLoading}>
            Save Changes
          </CustomButton>
          <CustomButton
            onClick={handleClose}
            variant="outlined"
            disabled={isLoading}
          >
            Cancel
          </CustomButton>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeMailDialog;
