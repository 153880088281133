import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import fromNow from "dayjs/plugin/relativeTime";
import { FC, useRef } from "react";
import "./CustomTable.module.scss";
import classes from "./CustomTable.module.scss";
import { TableColumnType } from "./types";

interface ICustomTable {
  type?: string;
  isTableHead?: boolean;
  stickyHeader?: boolean;
  columns: Array<TableColumnType> | [];
  rows: Array<any> | [];
  align?: "right" | "left" | "center";
  onClickRow?: (data: any) => void;
  onHandleFetch?: () => void;
  dashedStyle?: boolean;
}

const CustomTable: FC<ICustomTable> = ({
  isTableHead = true,
  stickyHeader = true,
  columns,
  rows,
  align = "left",
  onClickRow,
  onHandleFetch,
  dashedStyle = true,
}) => {
  dayjs.extend(fromNow);

  const parentRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLTableElement>(null);

  const onHandleScroll = () => {
    if (
      parentRef.current &&
      childRef.current &&
      parentRef?.current.offsetHeight + parentRef.current.scrollTop + 100 >=
        childRef.current.offsetHeight
    ) {
      onHandleFetch && onHandleFetch();
    }
  };

  return (
    <div
      className={`${classes["custom-table"]} custom-table`}
      onScroll={onHandleFetch && onHandleScroll}
      ref={parentRef}
    >
      <Table
        stickyHeader={stickyHeader}
        aria-label="sticky table"
        ref={childRef}
      >
        {isTableHead ? (
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={align}
                  sx={{ padding: "20px" }}
                  className={`th--${column.id}`}
                >
                  <span
                    onClick={() => column.onClick && column.onClick()}
                    className={classes["custom-table--header-item"]}
                  >
                    {column.label}
                  </span>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        ) : null}

        <TableBody>
          {rows.map((row) => (
            <TableRow
              hover
              onClick={() => {
                onClickRow && onClickRow(row.family.owner.id);
              }}
              className={classes["cursor-pointer"]}
              role="checkbox"
              sx={{
                height: "40px",
              }}
              key={row.id}
            >
              {columns.map((column) => {
                const value = row[column.id];
                return (
                  <TableCell
                    className={`td--${column.id}`}
                    key={column.id}
                    align={column.id === "operation" ? "right" : align}
                    sx={{
                      backgroundColor: "#ffffff",
                      borderBottom: "4px solid #f2f2f4",
                      color: "#545e6b",
                      padding: "16px",
                      ...column.style,
                    }}
                  >
                    {column?.renderFunction
                      ? column.renderFunction(row)
                      : value}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default CustomTable;
