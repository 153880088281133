import { paginationType } from "../../ts/types";
import { request } from "../axios-rq";

export const inviteFamilyRequest = (data: { email: string }) => {
  return request({ url: "family/invite", method: "post", data });
};

export const confirmInviteRequest = ({
  id,
  token,
}: {
  id: string;
  token: string;
}) => {
  return request({
    url: `family/${id}/invite/verify/${token}`,
    method: "post",
    data: {},
  });
};

export const deleteFamilyRequest = (userFamilyId: string) => {
  return request({
    url: `family/delete-member/${userFamilyId}`,
    method: "delete",
  });
};

export const getFamiliesRequest = ({
  limit = 10,
  offset = 0,
  query,
}: paginationType & { query: string }) => {
  return request({
    url: `family/?limit=${limit}&offset=${offset}&q=${query.toLowerCase()}`,
    method: "get",
  });
};

export const getParentsServices = ({
  userId,
  size = 10,
  offset = 0,
  sort,
  query,
}: paginationType & { sort: string; query: string; userId: string }) => {
  const dir = sort === "createdAt" ? "DESC" : "ASC";
  return request({
    url: `family/parents/${userId}/services`,
    method: "get",
    params: { sort, size, offset, dir, q: query },
  });
};

export const getFamilyParentsRequest = ({
  limit = 10,
  offset = 0,
  query,
}: paginationType & { query: string }) => {
  return request({
    url: `family/parents?limit=${limit}&offset=${offset}&q=${query.toLowerCase()}`,
    method: "get",
  });
};
