import { FC, memo } from "react";
import classes from "./HeadingPage.module.scss";

interface IPageHeading {
  headingName: string;
}

const HeadingPage: FC<IPageHeading> = memo(({ headingName }) => {
  return (
    <div>
      <p className="heading--page">{headingName}</p>
      <div className={classes.line}></div>
    </div>
  );
});

export default HeadingPage;
