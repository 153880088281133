import Loader from "../loader";

const SuspenseFallback = () => {
  return (
    <div className={"suspense-fallback"}>
      <Loader />
    </div>
  );
};

export default SuspenseFallback;
