import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import classes from "./Header.module.scss";
import CustomDrawer from "./ui/CustomDrawer";
import { DownloadButton } from "./ui/download-button/DownloadButton";
import HelpButton from "./ui/help-button";
import NavbarSettings from "./ui/navbar-settings";
import { ReactComponent as Logo } from "../../../../assets/icons/logo.svg";
import { pagePathMap, pages } from "../../constants";

const Header = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <AppBar
      className={classes.header}
      position="static"
      sx={{ padding: "8px 84px" }}
    >
      <Toolbar>
        <div className={classes.logo}>
          <Logo className={classes["logo-svg"]} onClick={handleLogoClick} />
        </div>

        <div className={classes.navbar}>
          {pages.map((page, index) => (
            <NavLink to={pagePathMap[page]} key={index}>
              <span className={classes.active}>{page}</span>
            </NavLink>
          ))}
          <DownloadButton />
          <a
            href={process.env.REACT_APP_TECHWELL_HELP_URL}
            target="_blank"
            rel="noreferrer"
          >
            <span className={classes.active}>Assist</span>
          </a>
        </div>
        <HelpButton />
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          className={classes[`burger-menu`]}
          onClick={toggleMenu}
        >
          <MenuIcon />
        </IconButton>
        {isDrawerOpen && (
          <CustomDrawer isOpen={isDrawerOpen} handleClose={toggleMenu} />
        )}
        <NavbarSettings />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
