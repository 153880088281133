import Cookies from "js-cookie";
import { ObjectKeysType } from "../ts/types";

export const cookiesOptions = (options?: ObjectKeysType) => {
  return {
    ...options,
  };
};
export const setCookies = (name: string, value: any) => {
  return Cookies.set(
    name,
    value,
    cookiesOptions({
      path: "/",
    })
  );
};
export const getCookies = (name: string) => {
  return Cookies.get(name);
};
export const removeCookies = (name: string) => {
  return Cookies.remove(name, cookiesOptions({ expires: 0, path: "/" }));
};
