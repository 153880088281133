import MuiPagination from "@mui/material/Pagination";
import React, { FC } from "react";
import classes from "./Pagination.module.scss";

interface Props {
  onChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  page: number;
  count: number;
}

const Pagination: FC<Props> = ({ onChange, page, count }) => {
  return (
    <MuiPagination
      className={classes.pagination}
      shape="rounded"
      count={count} // Calculate the total number of pages
      page={page}
      onChange={onChange}
      color="primary"
    />
  );
};

export default Pagination;
