import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import {
  profileInfoFields,
  profileNameFields,
  profileSchema,
} from "./constants";
import classes from "./Profile.module.scss";
import { useAppContext } from "../../../../context/AppContext";
import { useSaveProfile } from "../../../../services/utils/hooks/routes/useUser";
import { profileType, userType } from "../../../../services/utils/ts/types";
import CustomButton from "../../../ui/button";
import CustomDatePicker from "../../../ui/date-picker";
import CustomInput from "../../../ui/input/input";

const Profile = () => {
  const onSuccess = () => {};

  const { mutate, isLoading } = useSaveProfile(onSuccess);
  const { user } = useAppContext();
  const { firstName, lastName, phone, dob }: userType = user!;

  const profileDefaultValues = {
    firstName,
    lastName,
    dob: (dob && dayjs(dob)) || dayjs(""),
    phone: phone || "",
  };

  const state = useForm({
    mode: "onSubmit",
    defaultValues: profileDefaultValues,
    resolver: yupResolver(profileSchema),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = state;

  const onSubmit = (profileData: profileType) => {
    mutate(profileData);
  };

  return (
    <div className={classes["profile--page"]}>
      <p className="heading--section">
        Edit Profile (Full profile information required for service)
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes["profile--content"]}>
          {profileNameFields.map((field, index) => {
            return (
              <Controller
                key={index}
                control={control}
                name={field.name}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    name={field.name}
                    onChange={onChange}
                    selected={value}
                    label={field.label}
                    type={field.type}
                    errorMessage={errors[field.name]}
                  />
                )}
              />
            );
          })}
        </div>
        <div className={classes["profile--content"]}>
          {profileInfoFields.map((field, index) => {
            return (
              <Controller
                key={index}
                control={control}
                name={field.name}
                render={({ field: { onChange, value } }) =>
                  field.type !== "date" ? (
                    <CustomInput
                      name={field.name}
                      onChange={onChange}
                      selected={value}
                      label={field.label}
                      type={field.type}
                      errorMessage={errors[field.name]}
                    />
                  ) : (
                    <CustomDatePicker
                      label={field.label}
                      onChange={onChange}
                      selected={value}
                    />
                  )
                }
              />
            );
          })}
        </div>
        <CustomButton
          type="submit"
          variant="contained"
          disabled={isLoading}
          className={classes["save-changes-btn"]}
        >
          Save Changes
        </CustomButton>
      </form>
    </div>
  );
};

export default Profile;
