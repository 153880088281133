import { debounce } from "@mui/material";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import ListConfiguration from "../../../../components/auth/services/service-list/body/configuration";
import ListContainer from "../../../../components/auth/services/service-list/body/container";
import HeadingPageService from "../../../../components/auth/services/service-list/heading";
import CustomDialog from "../../../../components/ui/dialog";
import Loader from "../../../../components/ui/loader";
import Pagination from "../../../../components/ui/pagination";
import { useGetParentsServicesList } from "../../../../services/utils/hooks/routes/useFamily";
import {
  useDeleteService,
  useGetServicesList,
} from "../../../../services/utils/hooks/routes/useService";

const ServiceList = () => {
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");

  const [query, setQuery] = useState("");
  const [sort, setSort] = useState("name");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [idForDelete, setIdForDelete] = useState("");

  // pagination

  const [page, setPage] = useState(1);
  const size = 10;
  const offset = (page - 1) * size;

  const { data: parentData, isLoading: isParentDataLoading } =
    useGetParentsServicesList({
      userId,
      id: "services",
      offset,
      size,
      sort,
      query,
    });

  const { data: ownData, isLoading: isOwnDataLoading } = useGetServicesList({
    userId,
    id: "services",
    offset,
    size,
    sort,
    query,
  });

  // delete mutation
  const onDeleteSuccess = () => {
    window.location.reload();
  };
  const { mutate: deleteMutate } = useDeleteService(onDeleteSuccess);

  const data = parentData || ownData;
  const isLoading = isParentDataLoading || isOwnDataLoading;

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  // sorting
  const handleSortTypeChange = (name: string) => {
    setSort(name);
  };

  // searching

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setQuery(event.target.value);
  };

  // delete dialog
  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleDeleteClick = (id: string) => {
    setIsDialogOpen(true);
    setIdForDelete(id);
  };

  const debouncedHandleSearch = debounce(handleSearch, 1000);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <HeadingPageService isParentData={!!parentData} />
      <ListConfiguration
        onSortChange={handleSortTypeChange}
        onSearch={debouncedHandleSearch}
        query={query}
        value={sort}
      />
      <ListContainer
        data={data?.services || []}
        handleDelete={handleDeleteClick}
      />

      {data && data.count > size && (
        <Pagination
          onChange={handlePageChange}
          page={page}
          count={Math.ceil(data.count / size)}
        />
      )}

      {isDialogOpen && (
        <CustomDialog
          title="Are you sure you want to delete service?"
          onAction={() => {
            deleteMutate({ id: idForDelete });
          }}
          onClose={handleClose}
          isDialogOpen={isDialogOpen}
        ></CustomDialog>
      )}
    </>
  );
};

export default ServiceList;
