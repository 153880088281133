import * as yup from "yup";

export const passwordFields = [
  {
    name: "oldPassword",
    label: "Old Password",
    type: "password",
  },
  {
    name: "password",
    label: "New Password",
    type: "password",
  },
  {
    name: "confirmPassword",
    label: "Confirm Password",
    type: "password",
  },
] as const;

export const passwordDefaultValues = {
  oldPassword: "",
  password: "",
  confirmPassword: "",
};

export const passwordSchema = yup.object({
  oldPassword: yup.string().required("Old Password is required"),
  password: yup.string().required("Password is required"),
  confirmPassword: yup.string().required("Confirm Password is required"),
});
