import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FC, memo } from "react";
import classes from "./CustomSelect.module.scss";

interface ICustomSelect {
  options: { name: string; value: string }[];
  onChangeCB?: (value: string) => void;
  value: string;
}

const CustomSelect: FC<ICustomSelect> = memo(
  ({ options, onChangeCB, value }) => {
    const handleChange = (event: SelectChangeEvent) => {
      onChangeCB && onChangeCB(event.target.value);
    };

    return (
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label="Age"
        onChange={handleChange}
        className={classes.select}
        sx={{
          background: "white",
          height: "44px",
          width: "277px",
          border: "1px solid rgba(255, 255, 255, 0.25)",
          borderRadius: "35px",
        }}
      >
        {options.map((option, id) => {
          return (
            <MenuItem key={id} value={option.value}>
              {option.name}
            </MenuItem>
          );
        })}
      </Select>
    );
  }
);

export default CustomSelect;
