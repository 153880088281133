import {
  createServiceType,
  deleteServiceType,
  editServiceType,
  paginationType,
} from "../../ts/types";
import { request } from "../axios-rq";

export const createServiceRequest = (data: createServiceType) => {
  return request({ url: "services", method: "post", data });
};

export const getSevicesRequest = ({
  size = 10,
  offset = 0,
  sort,
  query,
}: paginationType & { sort: string; query: string }) => {
  const dir = sort === "createdAt" ? "DESC" : "ASC";
  return request({
    url: "services",
    method: "get",
    params: { sort, size, offset, dir, q: query },
  });
};

export const getServiceNameRequest = ({ query }: { query: string }) => {
  return request({
    url: "services/search",
    method: "get",
    params: { q: query },
  });
};

export const getServiceByID = ({ queryKey }: { queryKey: Array<string> }) => {
  return request({
    url: `services/${queryKey[1]}`,
    method: "get",
  });
};

export const editServiceRequest = (data: editServiceType) => {
  return request({
    url: "services/" + data.id,
    method: "put",
    data: {
      name: data.name,
      userName: data.userName,
      websiteLink: data.websiteLink,
      passwordResetLink: data.passwordResetLink,
    },
  });
};

export const deleteServiceRequest = (data: deleteServiceType) => {
  return request({
    url: "services/" + data.id,
    method: "delete",
  });
};
