import EditIcon from "@mui/icons-material/Edit";
import { GridRenderCellParams } from "@mui/x-data-grid/models";
import { Link, useNavigate } from "react-router-dom";
import classes from "./Actions.module.scss";
import { IService } from "../../../../../../services/utils/ts/interface";
import CustomButton from "../../../../../ui/button";

export const ServiceNameRenderer = (params: GridRenderCellParams) => {
  return <p className={classes["heading-par"]}>{params.value}</p>;
};

export const UserNameRenderer = (params: GridRenderCellParams) => {
  return <p className={classes["normal-par"]}>{params.value}</p>;
};

export const RemoveDetailsRenderer = ({
  params: { id },
  handleDelete,
}: {
  params: IService;
  handleDelete: (id: string) => void;
}) => {
  return (
    <CustomButton
      variant="text"
      sxStyles={{
        lineHeight: "1.43",
        margin: "0",
        borderRadius: "15px",
        width: "fit-content",
        padding: "16px 26px",
        fontSize: "15px",
      }}
      onClick={() => handleDelete(id)}
    >
      Remove Details
    </CustomButton>
  );
};

export const PasswordRenderer = ({ params }: { params: IService }) => {
  return (
    <>
      {params.passwordResetLink && (
        <Link
          to={params.passwordResetLink}
          className={classes["password-button"]}
          target="_blank"
        >
          Password Reset
        </Link>
      )}
    </>
  );
};

export const AccessRenderer = ({ params }: { params: IService }) => {
  const urlPattern = /(https?:\/\/[^\s]+)/g;
  const urls = params.websiteLink?.match(urlPattern);
  const url = urls?.length
    ? params.websiteLink
    : "https://www." + params.websiteLink;
  return (
    <>
      {params.websiteLink && (
        <Link to={url} className={classes["access-button"]} target="_blank">
          Website Link
        </Link>
      )}
    </>
  );
};

// export const PasswordRenderer = (params: GridRenderCellParams) => {
//   return (
//     <>
//       {params.value && (
//         <Link
//           to={params.value}
//           className={classes["password-button"]}
//           target="_blank"
//         >
//           Password Reset
//         </Link>
//       )}
//     </>
//   );
// };
//
// export const AccessRenderer = (params: GridRenderCellParams) => {
//   const urlPattern = /(https?:\/\/[^\s]+)/g;
//   const urls = params.value?.match(urlPattern);
//   const url = urls?.length ? params.value : "https://www." + params.value;
//
//   return (
//     <>
//       {params.value && (
//         <Link
//           to={url}
//           className={classes["access-button"]}
//           target="_blank"
//         >
//           Website Link
//         </Link>
//       )}
//     </>
//   );
// };

export const EditRenderer = (params: GridRenderCellParams) => {
  const navigate = useNavigate();
  const handleEditClick = () => {
    navigate("/services?id=" + params.id);
  };

  return <EditIcon className={classes.edit} onClick={handleEditClick} />;
};
