import * as yup from "yup";

export const mailFields = [
  {
    name: "email",
    label: "New Email Address",
    type: "email",
  },
  {
    name: "confirmEmail",
    label: "Confirm Email Address",
    type: "email",
  },
  {
    name: "password",
    label: "Password",
    type: "password",
  },
] as const;

export const mailDefaultValues = {
  email: "",
  confirmEmail: "",
  password: "",
};

export const mailSchema = yup.object({
  email: yup.string().required("Email is required"),
  confirmEmail: yup
    .string()
    .oneOf([yup.ref("email")], "Emails must match")
    .required("Mail Confirmation is required"),
  password: yup.string().required("Password is required"),
});
