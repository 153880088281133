import { memo } from "react";
import classes from "./Loader.module.scss";

const Loader = memo(() => {
  return (
    <div id="root">
      <div className={classes["loader-wrapper"]}>
        <div className={classes.loader}></div>
      </div>
    </div>
  );
});

export default Loader;
