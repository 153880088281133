import React, { FC, useMemo } from "react";
import "./style.scss";
import {
  AccessRenderer,
  PasswordRenderer,
  RemoveDetailsRenderer,
} from "./actions";
import { IService } from "../../../../../../services/utils/ts/interface";
import CustomTable from "../../../../../ui/table";
import { TableColumnType } from "../../../../../ui/table/types";

interface IListContainer {
  data: IService[] | [];
  handleDelete: (id: string) => void;
}

const ListContainer: FC<IListContainer> = ({ data, handleDelete }) => {
  const columns = useMemo<Array<TableColumnType>>(
    () => [
      {
        id: "name",
        label: "Service Name",
      },
      {
        id: "userName",
        label: "User Name",
      },
      {
        id: "websiteLink",
        label: "Website Link",
        renderFunction: (row) => <AccessRenderer params={row} />,
      },
      {
        id: "passwordResetLink",
        label: "Password Reset Link",
        renderFunction: (row) => <PasswordRenderer params={row} />,
      },
      {
        id: "removeDetails",
        label: "Remove ",
        renderFunction: (row) => (
          <RemoveDetailsRenderer params={row} handleDelete={handleDelete} />
        ),
      },
    ],
    [handleDelete]
  );

  return (
    <div>
      <CustomTable rows={data || []} columns={columns} />
    </div>
  );
};

export default ListContainer;
