import { useMemo, useState } from "react";
import { useMutation } from "react-query";
import styles from "./DownloadButton.module.scss";
import Modal from "../../../../../../components/ui/modal";
import { getTechWellVisionDownloadLink } from "../../../../../../services/utils/functions/routes/device";
import classes from "../../Header.module.scss";

export const DownloadButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isWindows = useMemo(() => navigator.userAgent.includes("Windows"), []);
  const isMac = useMemo(() => navigator.userAgent.includes("Macintosh"), []);

  const { mutateAsync } = useMutation({
    mutationKey: ["generate_link"],
    mutationFn: getTechWellVisionDownloadLink,
  });

  const onDownload = async () => {
    const { link: url } = await mutateAsync();

    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toggleModal();
  };

  const toggleModal = () => setIsModalOpen((prev) => !prev);

  return (
    <>
      <button
        onClick={toggleModal}
        className={
          isWindows ? classes.passive : isMac ? classes.active : classes.passive
        }
      >
        Add Device
      </button>
      <Modal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        handleOk={onDownload}
        hasIcon={false}
        okText={"Download"}
        title={"TechWell Vision"}
      >
        <div className={styles.content}>
          <p className={styles.info}>
            {
              "TechWell Vision is a remote desktop application, specifically tailored to meet the unique needs of older adults.\n\nWhether it's troubleshooting a tech issue, setting up a new device, or simply navigating everyday digital tasks like telehealth or eCommerce, TechWell Vision is here to help."
            }
          </p>
        </div>
      </Modal>
    </>
  );
};
