import { IService } from "../ts/interface";
import { userType } from "../ts/types";

export const getMailURL = (email: string) => {
  const protocol = "https://";
  const domain = email.split("@")[1];
  return protocol + domain;
};

export const getUsername = (user: userType) => {
  return user.firstName.slice(0, 1) + user.lastName.slice(0, 1);
};

export const truncateText = (text: string) => {
  if (text.length > 8) {
    return text.slice(0, 8) + "..";
  } else {
    return text;
  }
};

export const divideData = (data: IService[] | []) => {
  const dataLength = data.length;
  const dataHalfLength = dataLength / 2;

  const dataFirstHalf = data.filter((_, index) => {
    return index < dataHalfLength;
  });
  const dataSecondHalf = data.filter((_, index) => {
    return index >= dataHalfLength && index < dataLength;
  });

  return [dataFirstHalf, dataSecondHalf];
};
