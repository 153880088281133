import AddIcon from "@mui/icons-material/Add";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./HeadingPageService.module.scss";
import CustomButton from "../../../../ui/button";

interface IHeadingPageService {
  isParentData: boolean;
}

const HeadingPageService: FC<IHeadingPageService> = ({ isParentData }) => {
  const navigate = useNavigate();

  const handleAddClick = () => {
    navigate("/services");
  };
  return (
    <div className={classes["heading-page-service"]}>
      <p className="heading--page">My Apps & Services</p>
      <CustomButton
        className={classes.button}
        startIcon={<AddIcon />}
        onClick={handleAddClick}
        disabled={isParentData}
      >
        Create Service
      </CustomButton>
    </div>
  );
};

export default HeadingPageService;
