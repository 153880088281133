import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import SuspenseFallback from "../components/ui/suspense-fallback";
import AuthLayout from "../layout/auth";
import GuestLayout from "../layout/guest";
import AddService from "../pages/auth/services/add-service";
import ServiceList from "../pages/auth/services/services-list";
import Settings from "../pages/auth/settings";
import { routeType } from "../services/utils/ts/types";

const MyFamily = lazy(() => import("../pages/auth/myFamily"));
// const Benefits = lazy(() => import("../pages/auth/benefits"));
const Notfound = lazy(() => import("../pages/not-found"));
const Login = lazy(() => import("../pages/guest/login"));
const Register = lazy(() => import("../pages/guest/register"));
const ResetPassword = lazy(() => import("../pages/guest/reset-password"));
const Confirm = lazy(() => import("../pages/guest/confirm"));
const InvitationConfirm = lazy(
  () => import("../pages/guest/registerForInvitation")
);

const AppRoutes = () => {
  const authenticatedRoutes: Array<routeType> = [
    {
      path: "/",
      element: <ServiceList />,
    },
    {
      path: "/settings",
      element: <Settings />,
    },
    // {
    //   path: "/benefits",
    //   element: <Benefits />,
    // },
    {
      path: "/services",
      element: <AddService />,
    },
    {
      path: "/services-list",
      element: <ServiceList />,
    },
    {
      path: "/family",
      element: <MyFamily />,
    },
  ];

  const nonAuthenticatedRoutes: Array<routeType> = [
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/invitation-confirm",
      element: <InvitationConfirm />,
    },
    {
      path: "/invite/confirm",
      element: <Confirm />,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
  ];

  return (
    <Routes>
      {authenticatedRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <Suspense fallback={<SuspenseFallback />}>
              <AuthLayout>{route.element}</AuthLayout>
            </Suspense>
          }
        />
      ))}
      {nonAuthenticatedRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <Suspense fallback={<SuspenseFallback />}>
              <GuestLayout>{route.element}</GuestLayout>
            </Suspense>
          }
        />
      ))}
      <Route
        path={"*"}
        element={
          <Suspense fallback={<SuspenseFallback />}>
            <Notfound />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
