import * as yup from "yup";

export const serviceInputFields = {
  userName: {
    name: "userName",
    label: "User Name",
    type: "text",
  },
  websiteLink: {
    name: "websiteLink",
    label: "Website Link",
    type: "text",
  },
  passwordResetLink: {
    name: "passwordResetLink",
    label: "Password Reset Link",
    type: "text",
  },
} as const;

export const createServiceSchema = yup.object({
  websiteLink: yup.string(),
  passwordResetLink: yup.string(),
});

export const createServiceDefaultValues = {
  // name: "",
  userName: "",
  websiteLink: "",
  passwordResetLink: "",
};
