import { Context, createContext, ReactNode, useContext, useState } from "react";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/ui/loader";
import { removeCookies } from "../services/utils/functions/cookies";
import { useGetMe } from "../services/utils/hooks/routes/useUser";
import { IUser } from "../services/utils/ts/interface";
import { userType } from "../services/utils/ts/types";

interface IAppContext {
  getMe: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<userType, Error>>;
  logOut: () => void;
  isAuthenticated: boolean;
  user: userType | null;
}

const AppContext: Context<IAppContext> = createContext<IAppContext>(
  {} as IAppContext
);

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const [user, setUser] = useState<IUser>({
    isAuthenticated: false,
    user: null,
  });

  const isInitial = location.pathname === "/login";

  const logOut = () => {
    setUser({
      isAuthenticated: false,
      user: null,
    });
    navigate("/login");
  };

  const navigate = useNavigate();

  const {
    isLoading,
    error,
    refetch: getMe,
  } = useGetMe(
    (userData) => {
      setUser({
        isAuthenticated: true,
        user: userData,
      });
      isInitial ? navigate("/services-list") : navigate(location);
    },

    () => {
      removeCookies("techwell-token");
      navigate("/login");
    }
  );

  if (error) {
    return <>{error.message}</>;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <AppContext.Provider value={{ ...user, getMe, logOut }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
