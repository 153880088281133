import { Button, ButtonProps } from "@mui/material";
import { FC, memo, ReactNode } from "react";
import { ObjectKeysType } from "../../../services/utils/ts/types";

interface ICustomButton {
  children: ReactNode;
  variant?: "contained" | "outlined" | "text";
  customIcon?: JSX.Element;
  sxStyles?: any;
}

const CustomButton: FC<ICustomButton & ButtonProps> = memo(
  ({
    children,
    variant = "contained",
    // customIcon,
    sxStyles,
    ...buttonRestParams
  }) => {
    const style = {
      padding: "10px 20px",
      ...sxStyles,
    };

    const customStyle: ObjectKeysType = {
      contained: {
        bgcolor: "secondary.main",
        color: "secondary.contrastText",
        ":hover": {
          bgcolor: "secondary.main",
          color: "secondary.contrastText",
        },
        ...style,
      },
      outlined: {
        ":hover": {
          bgcolor: "secondary.main",
          color: "secondary.contrastText",
        },
        backgroundColor: "secondary.contrastText",
        color: "secondary.main",
        ...style,
      },
      text: {
        color: "warning.main",
        borderColor: "warning.main",
        ...style,
      },
    };

    return (
      <Button {...buttonRestParams} sx={customStyle[variant]}>
        {children}
      </Button>
    );
  }
);

export default CustomButton;
