import { Link } from "react-router-dom";
import classes from "./Footer.module.scss";
import {
  coPilotChromeURL,
  coPilotSafariURL,
  onboardingURL,
} from "../../constants";

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        {/* <div className={classes.section}>
          <p className="text--heading">SAVINGS</p>
          <Link to="/benefits" className="text--content">
            Benefits
          </Link>
        </div> */}
        <div className={classes.section}>
          <p className="text--heading">SERVICES</p>
          <Link to="/services-list" className="text--content">
            My Apps & Services
          </Link>
          <Link to="/family" className="text--content">
            My Family
          </Link>
        </div>
        <div className={classes.section}>
          <p className="text--heading">INFO</p>
          <Link to="/settings" className="text--content">
            Settings
          </Link>
          <Link to={onboardingURL} className="text--content">
            Onboarding
          </Link>
          <Link to={coPilotChromeURL} className="text--content">
            CoPilot: Chrome
          </Link>
          <Link to={coPilotSafariURL} className="text--content">
            CoPilot: Safari
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
