import classes from "./Settings.module.scss";
import Mail from "../../../components/auth/settings/mail";
import Password from "../../../components/auth/settings/password";
import Profile from "../../../components/auth/settings/profile";
import HeadingPage from "../../../components/ui/heading-page";

const Settings = () => {
  return (
    <div className={classes.settingsPage}>
      <div className={classes.settings}>
        <HeadingPage headingName="Account Settings" />
        <Profile />
        <Mail />
        <Password />
      </div>
    </div>
  );
};

export default Settings;
