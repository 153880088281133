import { useState } from "react";
import ChangeMailDialog from "./change-mail";
import classes from "./Mail.module.scss";
import { useAppContext } from "../../../../context/AppContext";

const Mail = () => {
  const { user } = useAppContext();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes["mail--page"]}>
      <div className="mail--header">
        <div className="heading--section">Change Mail</div>
        <p className="text--content">
          You can change your mail at any time, but you'll have to confirm the
          mail after the change
        </p>
      </div>
      <div className={classes["mail--content"]}>
        <p className={classes.mail}>{user?.email}</p>
        <button className={classes["change--button"]} onClick={handleClickOpen}>
          Change
        </button>
        {open && (
          <ChangeMailDialog
            open={open}
            onClose={handleClose}
            currentMail={user!.email}
          />
        )}
      </div>
    </div>
  );
};

export default Mail;
