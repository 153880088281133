import * as yup from "yup";

export const phoneFields = [
  { name: "phoneNumber", label: "Phone Number", type: "text" },
] as const;

export const phoneDefaultValues = {
  phoneNumber: "",
};

export const phoneSchema = yup.object({
  phoneNumber: yup
    .string()
    .required("Phone is required")
    .min(6, "Must be longer than or equal to 6 characters"),
});
