import FormHelperText from "@mui/material/FormHelperText";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { FC, memo } from "react";
import classes from "./CustomDatePicker.module.scss";

interface ICustomDatePicker {
  label: string;
  onChange: (arg0: unknown) => void;
  selected?: string | dayjs.Dayjs;
  customStyle?: Record<string, string>;
}

const CustomDatePicker: FC<ICustomDatePicker> = memo(
  ({ label, selected, onChange, customStyle }) => {
    return (
      <div className={classes["custom--datepicker"]}>
        <div className={classes["helper--text"]}>
          <FormHelperText sx={{ color: "#000000", fontWeight: 600 }}>
            {label}
          </FormHelperText>
        </div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={selected}
            onChange={onChange}
            slotProps={{
              textField: {
                error: false,
                sx: { width: "100%", ...customStyle },
              },
            }}
          />
        </LocalizationProvider>
      </div>
    );
  }
);

export default CustomDatePicker;
