import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { InputAdornment } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import { FC, memo, useState } from "react";
import { FieldError } from "react-hook-form";
import classes from "./CustomInput.module.scss";

interface ICustomInput {
  name: string;
  label: string;
  errorMessage?: FieldError;
  selected?: any;
  type: "text" | "password" | "email";
  onChange?: () => void;
  customStyle?: any;
  className?: string;
}

const CustomInput: FC<ICustomInput> = memo(
  ({
     onChange,
     selected,
     label,
     name,
     type = "text",
     errorMessage,
     customStyle,
     className
   }) => {


    const [inputType, setInputType] = useState(type);

    const onHandleChangeInputType = () => {
      setInputType(prev => prev === "password" ? "text" : "password");
    };

    return (
      <div className={classes["custom--input"]}>
        <div className={classes["helper--text"]}>
          <FormHelperText sx={{ color: "#000000", fontWeight: 600 }}>
            {label}
          </FormHelperText>
        </div>
        <TextField
          color="secondary"
          autoComplete="off"
          placeholder={name === "phone" ? "+1-234-567-8910" : `Enter ${label}`}
          value={selected}
          onChange={onChange}
          type={inputType}
          InputProps={{
            endAdornment: type === "password" ? (
              <InputAdornment position="end"
                              sx={{
                                cursor: "pointer"
                              }}
                              onClick={onHandleChangeInputType}
              >
                {inputType === "password" ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </InputAdornment>
            ) : null,
            sx: {
              width: "100%"
            }
          }}
          sx={{
            display: "block",
            marginBottom: "10px",
            width: "100%",
            ...customStyle
          }}
          className={className}
        />
        {errorMessage?.message && (
          <p className={classes.error}>{errorMessage?.message}</p>
        )}
      </div>
    );
  }
);

export default CustomInput;
