import { useSnackbar } from "notistack";
import React, { FC, useEffect } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { snackbarState } from "../../../services/store/atoms/stackbar.atom";

interface ISnackbar {
  children: React.ReactNode;
}

const Snackbar: FC<ISnackbar> = ({ children }) => {
  const snackbar = useRecoilValue(snackbarState);
  const resetSnackbar = useResetRecoilState(snackbarState);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (snackbar) {
      enqueueSnackbar(snackbar.text || 'Error', {
        variant: snackbar.variant,
      });

      resetSnackbar();
    }
  }, [enqueueSnackbar, resetSnackbar, snackbar]);

  return <>{children}</>;
};

export default Snackbar;
